// style
import '@/assets/stylus/main.styl'

// components
export { default as BaseButton } from './components/BaseButton.vue'
export { default as BaseCheckbox } from './components/BaseCheckbox.vue'
export { default as BaseChecklist } from './components/BaseChecklist.vue'
export { default as BaseInput } from './components/BaseInput.vue'
export { default as BaseSelect } from './components/BaseSelect.vue'
export { default as BaseSwitch } from './components/BaseSwitch.vue'
export { default as BaseRadio } from './components/BaseRadio.vue'
export { default as BaseTextarea } from './components/BaseTextarea.vue'
export { default as BaseAddress } from './components/BaseAddress.vue'
export { default as BasePhoneNumber } from './components/BasePhoneNumber.vue'
export { default as BaseProgressBar } from './components/BaseProgressBar.vue'
export { default as BaseZipcode } from './components/BaseZipcode.vue'
export { default as BaseFileUpload } from './components/BaseFileUpload.vue'
export { default as BaseMultiSelect } from './components/BaseMultiSelect.vue'

export { default as Modal } from './components/Modal.vue'

// functions
import { typesRepetition, typesRoute } from './AddressEnums'

// icons
export { default as IconAdd } from './components/icons/IconAdd.vue'
export { default as IconAddress } from './components/icons/IconAddress.vue'
export { default as IconArrowDown } from './components/icons/IconArrowDown.vue'
export { default as IconArrowheadDown } from './components/icons/IconArrowheadDown.vue'
export { default as IconArrowheadLeft } from './components/icons/IconArrowheadLeft.vue'
export { default as IconArrowheadRight } from './components/icons/IconArrowheadRight.vue'
export { default as IconArrowheadTop } from './components/icons/IconArrowheadTop.vue'
export { default as IconArrowLeft } from './components/icons/IconArrowLeft.vue'
export { default as IconArrowRight } from './components/icons/IconArrowRight.vue'
export { default as IconArrowTop } from './components/icons/IconArrowTop.vue'
export { default as IconBankCheck } from './components/icons/IconBankCheck.vue'
export { default as IconBook } from './components/icons/IconBook.vue'
export { default as IconBookmark } from './components/icons/IconBookmark.vue'
export { default as IconBrandLogoFavicon } from './components/icons/IconBrandLogoFavicon.vue'
export { default as IconBurger } from './components/icons/IconBurger.vue'
export { default as IconCalendar } from './components/icons/IconCalendar.vue'
export { default as IconCalendarCheckLight } from './components/icons/IconCalendarCheckLight.vue'
export { default as IconCall } from './components/icons/IconCall.vue'
export { default as IconCallback } from './components/icons/IconCallback.vue'
export { default as IconCard } from './components/icons/IconCard.vue'
export { default as IconCentre } from './components/icons/IconCentre.vue'
export { default as IconChecklist } from './components/icons/IconChecklist.vue'
export { default as IconCheque } from './components/icons/IconCheque.vue'
export { default as IconClassBookLight } from './components/icons/IconClassBookLight.vue'
export { default as IconClipboard } from './components/icons/IconClipboard.vue'
export { default as IconClock } from './components/icons/IconClock.vue'
export { default as IconCoinEuroLight } from './components/icons/IconCoinEuroLight.vue'
export { default as IconComment } from './components/icons/IconComment.vue'
export { default as IconCommentAdd } from './components/icons/IconCommentAdd.vue'
export { default as IconConnexion } from './components/icons/IconConnexion.vue'
export { default as IconConnexionLight } from './components/icons/IconConnexionLight.vue'
export { default as IconContactLight } from './components/icons/IconContactLight.vue'
export { default as IconConversation } from './components/icons/IconConversation.vue'
export { default as IconCp } from './components/icons/IconCp.vue'
export { default as IconCreditCard } from './components/icons/IconCreditCard.vue'
export { default as IconCross } from './components/icons/IconCross.vue'
export { default as IconDebit } from './components/icons/IconDebit.vue'
export { default as IconDegree } from './components/icons/IconDegree.vue'
export { default as IconDoc } from './components/icons/IconDoc.vue'
export { default as IconDocument } from './components/icons/IconDocument.vue'
export { default as IconDone } from './components/icons/IconDone.vue'
export { default as IconDownload } from './components/icons/IconDownload.vue'
export { default as IconEgalLight } from './components/icons/IconEgalLight.vue'
export { default as IconEnfant } from './components/icons/IconEnfant.vue'
export { default as IconExclamation } from './components/icons/IconExclamation.vue'
export { default as IconEyeHidden } from './components/icons/IconEyeHidden.vue'
export { default as IconEyeVisible } from './components/icons/IconEyeVisible.vue'
export { default as IconFacebook } from './components/icons/IconFacebook.vue'
export { default as IconFacebookLight } from './components/icons/IconFacebookLight.vue'
export { default as IconFilter } from './components/icons/IconFilter.vue'
export { default as IconFold } from './components/icons/IconFold.vue'
export { default as IconForm } from './components/icons/IconForm.vue'
export { default as IconGeoloc } from './components/icons/IconGeoloc.vue'
export { default as IconGiftCheck } from './components/icons/IconGiftCheck.vue'
export { default as IconGraduate } from './components/icons/IconGraduate.vue'
export { default as IconGroup } from './components/icons/IconGroup.vue'
export { default as IconGroupLight } from './components/icons/IconGroupLight.vue'
export { default as IconHome } from './components/icons/IconHome.vue'
export { default as IconInfoLight } from './components/icons/IconInfoLight.vue'
export { default as IconInformation } from './components/icons/IconInformation.vue'
export { default as IconInstagram } from './components/icons/IconInstagram.vue'
export { default as IconInstagramLight } from './components/icons/IconInstagramLight.vue'
export { default as IconItineraryLight } from './components/icons/IconItineraryLight.vue'
export { default as IconLess } from './components/icons/IconLess.vue'
export { default as IconLinkedin } from './components/icons/IconLinkedin.vue'
export { default as IconLinkedinLight } from './components/icons/IconLinkedinLight.vue'
export { default as IconLoader } from './components/icons/IconLoader.vue'
export { default as IconLock } from './components/icons/IconLock.vue'
export { default as IconMail } from './components/icons/IconMail.vue'
export { default as IconMoreHorizontal } from './components/icons/IconMoreHorizontal.vue'
export { default as IconMoreVertical } from './components/icons/IconMoreVertical.vue'
export { default as IconNotification } from './components/icons/IconNotification.vue'
export { default as IconPause } from './components/icons/IconPause.vue'
export { default as IconPencil } from './components/icons/IconPencil.vue'
export { default as IconPhoneLight } from './components/icons/IconPhoneLight.vue'
export { default as IconPlay } from './components/icons/IconPlay.vue'
export { default as IconPlayLight } from './components/icons/IconPlayLight.vue'
export { default as IconPodcast } from './components/icons/IconPodcast.vue'
export { default as IconPoi } from './components/icons/IconPoi.vue'
export { default as IconPrelevement } from './components/icons/IconPrelevement.vue'
export { default as IconPrice } from './components/icons/IconPrice.vue'
export { default as IconPrint } from './components/icons/IconPrint.vue'
export { default as IconProgression } from './components/icons/IconProgression.vue'
export { default as IconQuoteEnd } from './components/icons/IconQuoteEnd.vue'
export { default as IconQuoteStart } from './components/icons/IconQuoteStart.vue'
export { default as IconRdv } from './components/icons/IconRdv.vue'
export { default as IconRdvTel } from './components/icons/IconRdvTel.vue'
export { default as IconRefresh } from './components/icons/IconRefresh.vue'
export { default as IconScooling } from './components/icons/IconScooling.vue'
export { default as IconSearch } from './components/icons/IconSearch.vue'
export { default as IconSettings } from './components/icons/IconSettings.vue'
export { default as IconShare } from './components/icons/IconShare.vue'
export { default as IconSmartPhone } from './components/icons/IconSmartPhone.vue'
export { default as IconSort } from './components/icons/IconSort.vue'
export { default as IconSortDown } from './components/icons/IconSortDown.vue'
export { default as IconSortReset } from './components/icons/IconSortReset.vue'
export { default as IconSortUp } from './components/icons/IconSortUp.vue'
export { default as IconStage } from './components/icons/IconStage.vue'
export { default as IconStar } from './components/icons/IconStar.vue'
export { default as IconStats } from './components/icons/IconStats.vue'
export { default as IconStop } from './components/icons/IconStop.vue'
export { default as IconTeacher } from './components/icons/IconTeacher.vue'
export { default as IconTeacherLight } from './components/icons/IconTeacherLight.vue'
export { default as IconTrash } from './components/icons/IconTrash.vue'
export { default as IconTwitter } from './components/icons/IconTwitter.vue'
export { default as IconTwitterLight } from './components/icons/IconTwitterLight.vue'
export { default as IconUnfold } from './components/icons/IconUnfold.vue'
export { default as IconUnlocked } from './components/icons/IconUnlocked.vue'
export { default as IconUpload } from './components/icons/IconUpload.vue'
export { default as IconUser } from './components/icons/IconUser.vue'
export { default as IconVideo } from './components/icons/IconVideo.vue'
export { default as IconVirement } from './components/icons/IconVirement.vue'
export { default as IconVirementLight } from './components/icons/IconVirementLight.vue'
export { default as IconWebcam } from './components/icons/IconWebcam.vue'
export { default as IconWhatsappLight } from './components/icons/IconWhatsappLight.vue'
export { default as IconYoutube } from './components/icons/IconYoutube.vue'
export { default as IconYoutubeLight } from './components/icons/IconYoutubeLight.vue'
export { default as IconTikTokLight } from './components/icons/IconTikTokLight.vue'

//flags

export { default as FlagBelgium } from './components/flags/FlagBelgium.vue'
export { default as FlagFrance } from './components/flags/FlagFrance.vue'
export { default as FlagGermany } from './components/flags/FlagGermany.vue'
export { default as FlagGreatBritain } from './components/flags/FlagGreatBritain.vue'
export { default as FlagItaly } from './components/flags/FlagItaly.vue'
export { default as FlagNetherlands } from './components/flags/FlagNetherlands.vue'
export { default as FlagPortugal } from './components/flags/FlagPortugal.vue'
export { default as FlagSpain } from './components/flags/FlagSpain.vue'
export { default as FlagUSA } from './components/flags/FlagUSA.vue'

//illus

export { default as Illu3points } from './components/illus/Illu3points.vue'
export { default as Illu4x } from './components/illus/Illu4x.vue'
export { default as Illu50cent } from './components/illus/Illu50cent.vue'
export { default as IlluBilanPersonnalise } from './components/illus/IlluBilanPersonnalise.vue'
export { default as IlluBulb } from './components/illus/IlluBulb.vue'
export { default as IlluBulle } from './components/illus/IlluBulle.vue'
export { default as IlluCalendrier } from './components/illus/IlluCalendrier.vue'
export { default as IlluChecklist } from './components/illus/IlluChecklist.vue'
export { default as IlluCoachingScolaire } from './components/illus/IlluCoachingScolaire.vue'
export { default as IlluConseilPedagogique } from './components/illus/IlluConseilPedagogique.vue'
export { default as IlluContact } from './components/illus/IlluContact.vue'
export { default as IlluConversation } from './components/illus/IlluConversation.vue'
export { default as IlluCours10jours } from './components/illus/IlluCours10jours.vue'
export { default as IlluDateChoix } from './components/illus/IlluDateChoix.vue'
export { default as IlluDateFavorite } from './components/illus/IlluDateFavorite.vue'
export { default as IlluDiplome } from './components/illus/IlluDiplome.vue'
export { default as IlluEconomie } from './components/illus/IlluEconomie.vue'
export { default as IlluEleves } from './components/illus/IlluEleves.vue'
export { default as IlluEnglish } from './components/illus/IlluEnglish.vue'
export { default as IlluEnseignant } from './components/illus/IlluEnseignant.vue'
export { default as IlluEnseignant2 } from './components/illus/IlluEnseignant2.vue'
export { default as IlluEvolution } from './components/illus/IlluEvolution.vue'
export { default as IlluFonctionSupports } from './components/illus/IlluFonctionSupports.vue'
export { default as IlluGestionCentre } from './components/illus/IlluGestionCentre.vue'
export { default as IlluMail } from './components/illus/IlluMail.vue'
export { default as IlluManagementEnseignants } from './components/illus/IlluManagementEnseignants.vue'
export { default as IlluMedal } from './components/illus/IlluMedal.vue'
export { default as IlluNumber1 } from './components/illus/IlluNumber1.vue'
export { default as IlluOutilsPedagogiques } from './components/illus/IlluOutilsPedagogiques.vue'
export { default as IlluPaiement } from './components/illus/IlluPaiement.vue'
export { default as IlluParent } from './components/illus/IlluParent.vue'
export { default as IlluPartnership } from './components/illus/IlluPartnership.vue'
export { default as IlluPedagogieDynamique } from './components/illus/IlluPedagogieDynamique.vue'
export { default as IlluProfMusique } from './components/illus/IlluProfMusique.vue'
export { default as IlluProgression } from './components/illus/IlluProgression.vue'
export { default as IlluProgressionEtapes } from './components/illus/IlluProgressionEtapes.vue'
export { default as IlluProximite } from './components/illus/IlluProximite.vue'
export { default as IlluReferentAcadomia } from './components/illus/IlluReferentAcadomia.vue'
export { default as IlluSmartphoneAcadomia } from './components/illus/IlluSmartphoneAcadomia.vue'
export { default as IlluYes } from './components/illus/IlluYes.vue'
export { default as IlluCoursLive } from './components/illus/IlluCoursLive.vue'

//brand

export { default as BrandLogoTextboxAcadetmoi } from './components/brand/BrandLogoTextboxAcadetmoi.vue'
export { default as BrandLogoTextboxAdulte } from './components/brand/BrandLogoTextboxAdulte.vue'
export { default as BrandLogoTextboxAnglais } from './components/brand/BrandLogoTextboxAnglais.vue'
export { default as BrandLogoTextboxCollege } from './components/brand/BrandLogoTextboxCollege.vue'
export { default as BrandLogoTextboxLycee } from './components/brand/BrandLogoTextboxLycee.vue'
export { default as BrandLogoTextboxMusique } from './components/brand/BrandLogoTextboxMusique.vue'
export { default as BrandLogoTextboxPrimaire } from './components/brand/BrandLogoTextboxPrimaire.vue'
export { default as BrandLogoTextboxSuperieur } from './components/brand/BrandLogoTextboxSuperieur.vue'
export { default as BrandLogoTextboxEcolea } from './components/brand/BrandLogoTextboxEcolea.vue'
export { default as BrandLogoTextbox } from './components/brand/BrandLogoTextbox.vue'
export { default as BrandLogo } from './components/brand/BrandLogo.vue'

export { typesRepetition, typesRoute }
